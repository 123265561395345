import React from "react"
import { graphql } from "gatsby"
import PrivacyPolicyComponent from "../components/privacy-policy/privacy-policy.components"

const PrivacyPolicyPage = () => {
  return (
      <PrivacyPolicyComponent />
  )
}

export default PrivacyPolicyPage
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
